require('./bootstrap');

$(document).ready(function() {
    $('#faq-accordion .accordion-button').on('click', function() {
        // Collapse all accordion sections
        $('#faq-accordion .accordion-collapse').collapse('hide');

        // Expand the clicked section if it was collapsed
        if (!$(this).hasClass('collapsed')) {
            $(this).next('.accordion-collapse').collapse('show');
        }
    });
    
    $('#maintenance-schedule-accordion .accordion-button').on('click', function() {
        // Collapse all accordion sections
        $('#maintenance-schedule-accordion .accordion-collapse').collapse('hide');

        // Expand the clicked section if it was collapsed
        if (!$(this).hasClass('collapsed')) {
            $(this).next('.accordion-collapse').collapse('show');
        }
    });

    $('.accordion-button').on('click', function () {
        var targetId = $(this).attr('aria-controls');
        var target = $('#' + targetId);
        // Check if the target accordion item is about to be shown
        if (!target.hasClass('show')) {
            var parentId = targetId.slice(-1)
            $('.icon-rotate.hightlight_icon').removeClass('hightlight_icon');
            $('.benefit-icon-' + parentId).addClass('hightlight_icon');
        }
    });
});

